import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersRequestAction } from "./../../../../../redux/actions/customerActions";
import { getAgentsListRequestAction } from "redux/actions/agentActions";
import DropDown from "components/DropDown";
import { superAdmin } from "constant";
import { GET_ALL_USERS } from "config/apiRoutes";
import PaginationTable from "components/PaginationTable";
// --------------------------------------------------------------------------------
const AllUsers = ({ renderTopToolbar, from, to, setParams }) => {
  const roles = useSelector((state) => state.auth.user.roles);

  const dispatch = useDispatch();

  const [agent, setAgent] = useState(0);

  const agentList = useSelector((state) => state.agent.agentsList);
  const permissions = useSelector((state) => state.auth.user.permissions);

  useEffect(() => {
    dispatch(getAgentsListRequestAction());
  }, []);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        header: "User's Full Name",
        size: 150,
        Cell: ({ cell }) => <span>{cell.getValue()}</span>,
      },
      {
        accessorKey: "agent",
        header: "Agent",
        size: 150,
      },
      {
        accessorKey: "email", //normal accessorKey
        header: "Email",
        Cell: ({ cell, row }) => {
          return permissions.some((p) => p === "See Profile User") &&
            row.original.pepStatus ? (
            <Link to={`/app/profile/${row.original.id}/Summary`}>
              {cell.getValue()}
            </Link>
          ) : (
            <span>{cell.getValue()}</span>
          );
        },
      },
      // {
      //   accessorKey: "email", // normal accessorKey
      //   header: "Email",
      //   Cell: ({ cell, row }) => {
      //     return permissions.some((p) => p === "See Profile User") ? (
      //       <Link to={`/app/profile/${row.original.id}/Summary`}>
      //         {cell.getValue()}
      //       </Link>
      //     ) : (
      //       <span>{cell.getValue()}</span>
      //     );
      //   },
      // },
      {
        accessorKey: "country_code",
        header: "Mobile Country Code",
        size: 150,
      },
      {
        accessorKey: "mobile",
        header: "Mobile",
        size: 150,
      },
      {
        accessorKey: "pepStatus",
        header: "PeP Status",
        size: 150,
      },
      {
        accessorKey: "registerTime",
        header: "Register Time",
        size: 150,
      },
    ];

    // Conditionally add columns based on roles
    if (roles.some((role) => role.name === superAdmin)) {
      baseColumns.push(
        {
          accessorKey: "kycStatus",
          header: "KYC Status",
          size: 150,
        },
        {
          accessorKey: "status",
          header: "User Status",
          size: 150,
        },
        {
          accessorKey: "pepId",
          header: "PeP ID",
          size: 150,
        }
      );
    }

    return baseColumns;
  }, [permissions, roles, superAdmin]);

  return (
    <PaginationTable
      title="All Users"
      apiEndpoint={GET_ALL_USERS}
      columns={columns}
      renderTopToolbar={
        <Grid item>
          <Box sx={{ width: 238 }}>
            <DropDown
              label="Agent"
              schema={{ label: "name", value: "id" }}
              items={[{ name: "ALL", id: 0 }, ...agentList]}
              value={agent}
              onChange={(event) => setAgent(event.target.value)}
            />
          </Box>
        </Grid>
      }
      initialFilters={{ agentId: 0 }}
    />
  );
};

export default AllUsers;
