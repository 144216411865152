import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "components/CustomTable";
import { getAdminWallet } from "redux/actions/adminActions";
import { Avatar, Box, Typography } from "@mui/material";
import { CurrencyFlags } from "constants/constants";
// --------------------------------------------------------------------------------
const AgentWallets = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const { adminId } = useSelector((state) => ({
    adminId: state.auth.user.id,
  }));
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  useEffect(() => {
    dispatch(getAdminWallet({ adminId, setData }));
  }, [pagination.pageIndex, pagination.pageSize]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "currency",
        header: "Wallet",
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={CurrencyFlags[row.original.currency]}
              sx={{ width: 25, height: 25 }}
            />
            <Typography sx={{ ml: 1 }}>{row.original.currency}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "identification",
        header: "Wallet Id",
      },
      {
        accessorKey: "balance",
        header: "Balance",
      },
      {
        accessorKey: "pending",
        header: "Pending",
      },
      {
        accessorKey: "minimumBalance",
        header: "Minimum Balance",
      },
    ],
    []
  );

  return (
    <CustomTable
      label="Wallets"
      data={data}
      columns={columns}
      manualPagination={false}
    />
  );
};

export default AgentWallets;
