import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "components/DropDown";
import { getAgentsListRequestAction } from "redux/actions/agentActions";
import { superAdmin } from "constant";
import { GET_VIRTUAL_CARDS } from "config/apiRoutes";
import PaginationTable from "components/PaginationTable";
// --------------------------------------------------------------------------------
const VirtualCards = ({ from, to, renderTopToolbar, setParams }) => {
  const dispatch = useDispatch();

  const [agent, setAgent] = useState("all");
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const paginationTableRef = useRef();

  const { virtualCards, roles } = useSelector((state) => ({
    virtualCards: state.card.virtualCards,
    roles: state.auth.user.roles,
  }));
  const agentsList = useSelector((state) => state.agent.agentsList);
  const permissions = useSelector((state) => state.auth.user.permissions);

  useEffect(() => {
    dispatch(getAgentsListRequestAction());
  }, []);

  useEffect(() => {
    !isInitialLoad
      ? paginationTableRef.current.refetch()
      : setIsInitialLoad(false);
  }, [agent]);

  const isPermission =
    permissions.includes("See Physical Cards") &&
    permissions.includes("See Virtual Cards");

  const isSuperAdmin = roles.some((role) => role.name === superAdmin);
  const columns = useMemo(
    () => [
      ...(isSuperAdmin
        ? [
            {
              accessorKey: "cardId",
              header: "Pep Card Id",
              Cell: ({ cell, row }) =>
                isPermission ? (
                  <Link target="_blank" to={`/cards/${row.original.id}`}>
                    {cell.getValue()}
                  </Link>
                ) : (
                  cell.getValue()
                ),
            },
          ]
        : []),
      ...(isSuperAdmin
        ? [
            {
              accessorKey: "agent",
              header: "Agent",
            },
          ]
        : []),
      {
        accessorKey: "cardName",
        header: "Full Name",
        Cell: ({ cell, row }) =>
          isPermission ? (
            <Link
              target="_blank"
              to={`/app/profile/${row.original.userId}/Summary`}
            >
              {cell.getValue()}
            </Link>
          ) : (
            cell.getValue()
          ),
      },
      {
        accessorKey: "email", //normal accessorKey
        header: "Email",
        size: 200,
        Cell: ({ cell, row }) =>
          isPermission ? (
            <Link
              target="_blank"
              to={`/app/profile/${row.original.userId}/Summary`}
            >
              {cell.getValue()}
            </Link>
          ) : (
            cell.getValue()
          ),
      },
      {
        accessorKey: "cardNumber",
        header: "Card Number",
        Cell: ({ cell, row }) =>
          isPermission ? (
            <Link target="_blank" to={`/cards/${row.original.id}`}>
              {cell.getValue()}
            </Link>
          ) : (
            cell.getValue()
          ),
      },
      {
        accessorKey: "fee",
        header: "Fee",
        // Cell: ({ cell }) => cell.getValue(),
        Cell: ({ cell }) => 0,
      },
      {
        accessorKey: "brand",
        header: "Brand",
        size: 100,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 100,
      },
      // {
      //   accessorKey: "modifiedBy",
      //   header: "Modified by",
      //   size: 100,
      // },
      {
        accessorKey: "createdTime",
        header: "Created Time",
      },
      {
        accessorKey: "lastUpdate",
        header: "Last Update",
      },
    ],
    []
  );

  return (
    <PaginationTable
      ref={paginationTableRef}
      apiEndpoint={GET_VIRTUAL_CARDS}
      title="Virtual Cards"
      renderTopToolbar={
        isSuperAdmin && (
          <Grid item>
            <Box sx={{ width: 238 }}>
              <DropDown
                label="Agent"
                schema={{ label: "name", value: "id" }}
                items={
                  agentsList
                    ? [{ name: "ALL", id: "all" }, ...agentsList]
                    : [{ name: "ALL", id: "all" }]
                }
                value={agent}
                onChange={(event) => setAgent(event.target.value)}
              />
            </Box>
          </Grid>
        )
      }
      columns={columns}
      initialFilters={{ agentId: agent }}
    />
  );
};

export default VirtualCards;
