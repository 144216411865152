import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getStarterUsersRequestAction } from "./../../../../../redux/actions/customerActions";
import CustomTable from "components/CustomTable";
import { GET_STARTER_USERS } from "config/apiRoutes";
// --------------------------------------------------------------------------------
const StarterUsers = () => {
  const dispatch = useDispatch();

  const { starterUsers } = useSelector((state) => ({
    starterUsers: state.customer.starterUsers,
  }));
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  useEffect(() => {
    dispatch(
      getStarterUsersRequestAction({
        limit: pagination.pageSize,
        offset: pagination.pageIndex + 1,
      })
    );
  }, [dispatch, pagination.pageIndex, pagination.pageSize]);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        header: "User's Full Name",
        size: 150,
        Cell: ({ cell, row }) => (
          <Link to={`/users/pending-users/${row.original.id}`}>
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "agent",
        header: "Agent",
        size: 150,
      },
      {
        accessorKey: "email", //normal accessorKey
        header: "Email",
        size: 200,
        Cell: ({ cell, row }) => (
          <Link to={`/app/profile/${row.original.id}/Summary`}>
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "country_code",
        header: "Mobile Country Code",
        size: 150,
      },
      {
        accessorKey: "mobile",
        header: "Mobile",
        size: 150,
      },
      {
        accessorKey: "kycStatus",
        header: "KYC Status",
        size: 150,
      },
      {
        accessorKey: "status",
        header: "User Status",
        size: 150,
      },
      {
        accessorKey: "pepId",
        header: "PeP ID",
        size: 150,
      },
      {
        accessorKey: "pepStatus",
        header: "PeP Status",
        size: 150,
      },
      {
        accessorKey: "pepRejectionReason",
        header: "Pep Rejection Reason",
        size: 250,
      },
      {
        accessorKey: "premiumRequest",
        header: "Premium Request",
        size: 150,
      },
      {
        accessorKey: "registerTime",
        header: "Register Time",
        size: 150,
      },
    ],
    []
  );

  return (
    <CustomTable
      title="Starter Users"
      data={starterUsers.records}
      columns={columns}
      onPaginationChange={setPagination}
      state={{ pagination, loading: false }}
      rowCount={starterUsers.count}
    />
  );
};

export default StarterUsers;
