import React, { useEffect, useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes, Navigate } from "react-router-dom";
import "assets/vendors/style";
import defaultTheme from "./themes/defaultTheme";
import "styles/AppStyles.scss";
import SignIn from "./SignIn";
import { useSelector } from "react-redux";
import MainApp from "./../app/index";
import ForgotPassword from "./ForgotPassword";

const App = (props) => {
  const applyTheme = createTheme(defaultTheme);
  const { token } = useSelector((state) => state.auth);

  if (token)
    return (
      <ThemeProvider theme={applyTheme}>
        <MainApp />
      </ThemeProvider>
    );
  else
    return (
      <ThemeProvider theme={applyTheme}>
        <Routes>
          <Route path="login" element={<SignIn />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="*" element={<Navigate to="login" />} />
        </Routes>
      </ThemeProvider>
    );
};

export default App;
