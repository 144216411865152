import indigo from "@mui/material/colors/indigo";
import pink from "@mui/material/colors/pink";
import {
  blackColor,
  primaryColor,
  successColor,
  warningColor,
} from "assets/colors";

export default {
  palette: {
    primary: {
      main: primaryColor,
      // light: indigo[300],
      // main: indigo[500],
      // dark: indigo[700],
      contrastText: "#fff",
    },
    secondary: {
      light: pink[300],
      main: pink["A200"],
      dark: pink[700],
      contrastText: "#fff",
    },
    success: {
      main: successColor,
    },
    warning: {
      main: warningColor,
    },
    black: {
      main: blackColor,
    },
  },
  status: {
    danger: "orange",
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: "capitalize",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",
        },
      },
    },
  },
};
