import {
  GET_CURRENCIES_FAILURE,
  GET_CURRENCIES_STARTED,
  GET_CURRENCIES_SUCCESS,
  SET_CURRENCY,
} from "redux/ActionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: null,
  currenciesList: [],
  selectedCurrency: "all",
  error: "",
};
export const CurrencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENCIES_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        rType: GET_CURRENCIES_SUCCESS,
        currenciesList: [
          ...action.payload.currenciesList.map((currency) => ({
            name: currency.code,
            id: currency.id,
          })),
        ],
        error: "",
      };
    case GET_CURRENCIES_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    case SET_CURRENCY:
      return {
        ...state,
        selectedCurrency: action.payload,
      };
    default:
      return state;
  }
};
