import { GET_CURRENCIES_FAILURE, GET_CURRENCIES_STARTED, GET_CURRENCIES_SUCCESS, SET_CURRENCY } from "redux/ActionTypes";
import {
    getAgentsListAPI,
} from "services/API/agent";
import { getCurrenciesListAPI } from "services/API/currency";

// --------------------------------------------------------------------------------

export const getCurrenciesListStartedAction = () => ({
    type: GET_CURRENCIES_STARTED,
});

export const getCurrenciesListSuccessAction = (payload) => ({
    type: GET_CURRENCIES_SUCCESS,
    payload,
});

export const getCurrenciesListFailureAction = (message) => ({
    type: GET_CURRENCIES_FAILURE,
    payload: message,
});

export const getCurrenciesListRequestAction = () => {
    return async (dispatch) => {

        dispatch(getCurrenciesListStartedAction());
        try {
            let response = await getCurrenciesListAPI();
            console.log(response)
            if (!response.error) {
                dispatch(getCurrenciesListSuccessAction({ currenciesList: response.data }));
            } else {
                dispatch(getCurrenciesListFailureAction(response.data.error_description));
            }
        } catch (error) {
            console.log(error);
            dispatch(getCurrenciesListFailureAction(error.error_description));
        }
    };
};

export const setCurrency = (currency) => ({
    type: SET_CURRENCY,
    payload: currency,
});

