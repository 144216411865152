import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material";
import AppDatePicker from "components/AppDatePicker";
import CustomTable from "components/CustomTable";
import DropDown from "components/DropDown";
import Dayjs from "dayjs";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { CsvBuilder } from "filefy";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_BASE_URL } from "config/apiRoutes";

const PaginationTable = forwardRef(
  (
    {
      title,
      columns,
      apiEndpoint,
      initialFilters = {},
      datePicker = true,
      renderTopToolbar,
    },
    ref
  ) => {
    const [data, setData] = useState({ records: [], count: 0 });
    const [tableLoading, setTableLoading] = useState(true);
    //   const [params, setParams] = useState({});
    const [loading, setLoading] = useState(false);
    const [from, setFrom] = useState(Dayjs().subtract(7, "day"));
    const [to, setTo] = useState(Dayjs().add(1, "day"));
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 10,
    });

    const token = useSelector((state) => state.auth.token);

    const filteredInitialFilters = Object.keys(initialFilters).reduce(
      (acc, key) => {
        if (initialFilters[key] !== 0) acc[key] = initialFilters[key];
        return acc;
      },
      {}
    );

    useEffect(() => {
      fetchData();
    }, [from, to, pagination.pageIndex, pagination.pageSize]);

    // Expose the fetchData function to the parent component via ref
    useImperativeHandle(ref, () => ({
      refetch: fetchData, // The parent can call refetch to trigger this function
    }));

    const fetchData = async () => {
      setTableLoading(true);
      let keys = Object.keys(filteredInitialFilters);
      let values = Object.values(filteredInitialFilters);
      let queryParams = `${keys
        .map((key, index) => `${key}=${values[index]}`)
        .join("&")}`;
      let date = datePicker
        ? `&from=${from.format("YYYY-MM-DD")}&to=${to.format("YYYY-MM-DD")}${
            queryParams ? `&${queryParams}` : ""
          }`
        : "";

      try {
        const response = await axios.get(
          `${API_BASE_URL}/${apiEndpoint}?limit=${pagination.pageSize}&offset=${
            pagination.pageIndex + 1
          }${date}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setTableLoading(false);
        setData(response.data.data);
      } catch (error) {
        setTableLoading(false);
        console.error("Error fetching data:", error);
        throw error;
      }
    };

    const fetchPage = async (page) => {
      setLoading(true);

      let keys = Object.keys(filteredInitialFilters);
      let values = Object.values(filteredInitialFilters);
      let queryParams = `${keys
        .map((key, index) => `${key}=${values[index]}`)
        .join("&")}`;
      let date = datePicker
        ? `from=${from.format("YYYY-MM-DD")}&to=${to.format("YYYY-MM-DD")}${
            queryParams ? `&${queryParams}` : ""
          }`
        : "";

      try {
        const response = await axios.get(
          `${API_BASE_URL}/${apiEndpoint}?${date}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setLoading(false);
        return response.data.data.records;
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
        throw error;
      }
    };

    const exportToCSV = async () => {
      const allData = await fetchPage();

      // Ensure that allData is an array of objects
      if (!Array.isArray(allData) || allData.length === 0) {
        console.error(
          "Fetched data is not in the expected format or is empty."
        );
        return;
      }

      // Extract columns and rows for CSV
      const columns = Object.keys(allData[0]);
      const rows = allData.map((row) => columns.map((col) => row[col]));

      let csvBuilder = new CsvBuilder(`${title} - ${to.format("YYYY-MM-DD")}`)
        .setDelimeter(",")
        .setColumns(columns)
        .addRows(rows)
        .exportFile();
    };

    return (
      <CustomTable
        title={title}
        data={data.records}
        columns={columns}
        renderTopToolbar={
          <Grid container spacing={2} sx={{ px: 2, pb: 2 }}>
            <Grid item>
              <AppDatePicker
                label="From"
                value={from}
                onChange={(e) => setFrom(e)}
              />
            </Grid>
            <Grid item>
              <AppDatePicker label="To" value={to} onChange={(e) => setTo(e)} />
            </Grid>
            {renderTopToolbar}
            <Grid item sx={{ ml: "auto" }}>
              <LoadingButton
                type="submit"
                onClick={exportToCSV}
                variant="contained"
                color="primary"
                loading={loading}
                startIcon={<FileDownloadOutlinedIcon />}
              >
                CSV
              </LoadingButton>
            </Grid>
          </Grid>
        }
        onPaginationChange={setPagination}
        state={{ pagination, isLoading: tableLoading }}
        rowCount={data.count}
      />
    );
  }
);

export default PaginationTable;
