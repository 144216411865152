import React, {useMemo} from "react";
import {API_ADMIN_LIST} from "constants/constants";
import withTable from "HOC/withTable/withTable";
import APIs from "services/API";
import useAPI from "services/hook";
import CustomTable from "components/CustomTable";
import {Link} from "react-router-dom";
// --------------------------------------------------------------------------------
const AdminList = ({data, reRender}) => {
    console.log(data)
    const apiDeleteAdmin = useAPI(APIs.apiDeleteAdmin);

    const handleDeleteAdmin = async (id) => {
        const result = await apiDeleteAdmin.request({id});
        if (result) reRender();
    };


    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => `${row.firstName} ${row.lastName}`,
                header: "Full Name",
                size: 150,
                Cell: ({cell, row}) => {

                    return (
                        <Link to={`/app/adminProfile/${row.original.id}/Fees`}>
                            {row.original.fullName}
                        </Link>
                    )
                }
            },
            {
                accessorKey: "email", //normal accessorKey
                header: "Email",
                size: 150,
            },
            {

                accessorKey: "roles",
                header: "roles",
                size: 150,
                Cell: ({cell, row}) => {

                    console.log(row.original)
                    if (row.original.roles.length > 0) {
                        const roles = row.original.roles.toString()

                        return (
                            <>{roles}</>

                        )
                    } else {
                        return (<>This admin currently has no assigned roles.</>)
                    }
                }
            },
            {

                accessorKey: "agents",
                header: "agents",
                size: 150,
                Cell: ({cell, row}) => {

                    if (row.original.agents.length > 0) {
                        const agents = row.original.agents;

                        return (
                             <>{agents.map(agent => agent.name).join(', ')}</>
                        );
                    } else {
                        return <>This admin currently has no assigned agents.</>;
                    }


                }
            }
            // {
            //   field: "role",
            //   headerName: "Role",
            //   renderCell: ({ role }) => role.join(", "),
            // },
        ],
        []
    );

    return (
        <CustomTable
            label="Admin List"
            data={data}
            columns={columns}
            // onPaginationChange={setPagination}
            // state={{ pagination, loading: false }}
            // rowCount={data.count}
            manualPagination={false}
        />
    );
};

export default withTable(AdminList, {
    title: "Admin List",
    apiAddress: API_ADMIN_LIST,
    hasDatePicker: false,
});
