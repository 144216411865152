import React, { useEffect, useMemo, useRef, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Link } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "components/DropDown";
import { getAgentsListRequestAction } from "redux/actions/agentActions";
import { GET_PHYSICAL_CARDS } from "config/apiRoutes";
import PaginationTable from "components/PaginationTable";
// --------------------------------------------------------------------------------
const PhysicalCards = () => {
  const dispatch = useDispatch();

  const [agent, setAgent] = useState("all");
  const agentsList = useSelector((state) => state.agent.agentsList);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const paginationTableRef = useRef();

  const { physicalCards } = useSelector((state) => ({
    physicalCards: state.card.physicalCards,
  }));

  useEffect(() => {
    dispatch(getAgentsListRequestAction());
  }, []);

  useEffect(() => {
    !isInitialLoad
      ? paginationTableRef.current.refetch()
      : setIsInitialLoad(false);
  }, [agent]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "cardId",
        header: "Pep Card ID",
        Cell: ({ cell, row }) => (
          <Link target="_blank" to={`/cards/${row.original.id}`}>
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "agent",
        header: "Agent",
      },
      {
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        header: "User",
        Cell: ({ cell, row }) => (
          <Link
            target="_blank"
            to={`/app/profile/${row.original.userId}/Summary`}
          >
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "email", //normal accessorKey
        header: "Email",
        size: 200,
        Cell: ({ cell, row }) => (
          <Link
            target="_blank"
            to={`/app/profile/${row.original.userId}/Summary`}
          >
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "cardNumber",
        header: "Card Number",
        Cell: ({ cell, row }) => (
          <Link target="_blank" to={`/cards/${row.original.id}`}>
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "fee",
        header: "Fee",
        Cell: ({ cell }) => cell.getValue(),
      },
      {
        accessorKey: "brand",
        header: "Brand",
        size: 100,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 100,
      },
      {
        accessorKey: "modifiedBy",
        header: "Modified by",
        size: 100,
      },
      {
        accessorKey: "createdTime",
        header: "Created Time",
      },
      {
        accessorKey: "lastUpdate",
        header: "Last Update",
      },
    ],
    []
  );

  return (
    <PaginationTable
      ref={paginationTableRef}
      title="Physical Cards"
      apiEndpoint={GET_PHYSICAL_CARDS}
      renderTopToolbar={
        <Grid item>
          <Box sx={{ width: 238 }}>
            <DropDown
              label="Agent"
              schema={{ label: "name", value: "id" }}
              items={
                agentsList
                  ? [{ name: "ALL", id: "all" }, ...agentsList]
                  : [{ name: "ALL", id: "all" }]
              }
              value={agent}
              onChange={(event) => setAgent(event.target.value)}
            />
          </Box>
        </Grid>
      }
      columns={columns}
      initialFilters={{ agentId: agent }}
    />
  );
};

// export default withDateRangePicker(PhysicalCards);

export default PhysicalCards;
