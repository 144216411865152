import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from "@mui/material";
import { getCurrenciesRequestAction } from "redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "components/DropDown";
import {
  createTopUpIbanAction,
  getTopUpCountriesAction,
} from "redux/actions/topupActions";
import { hideMessage, showMessage } from "components/Modal";
import { LoadingButton } from "@mui/lab";
// --------------------------------------------------------------------------------
const TopUpByBankTransfer = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState("");
  const [iban, setIban] = useState("");
  const [countryName, setCountryName] = useState("");
  const [bankName, setBankName] = useState("");
  const [holderName, setHolderName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getCurrenciesRequestAction({ setCurrencies }));
  }, []);

  return (
    <Card>
      <CardHeader title="Top-up by Bank Transfer" />
      <CardContent>
        <Grid container spacing={3}>
          {/* <Grid item xs={3}>
            <Autocomplete
              label="Users"
              options={users}
              onChange={(event, value) => setUserId(value.id)}
              getOptionLabel={(option) => option.email}
            />
          </Grid> */}
          <Grid item xs={3}>
            <DropDown
              label="Currencies"
              schema={{ label: "code", value: "id" }}
              items={currencies}
              onChange={(e) => setCurrency(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Country"
              value={countryName}
              onChange={(e) => setCountryName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Bank"
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Account Holder Name"
              value={holderName}
              onChange={(e) => setHolderName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Iban"
              value={iban}
              onChange={(e) => setIban(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => {
            dispatch(
              createTopUpIbanAction({
                bankName: bankName ? bankName : null,
                countryName: countryName,
                currencyId: currency,
                holderName,
                iban,
                setLoading,
                onSuccess: () =>
                  showMessage({
                    description: "Iban has been successfully generated.",
                    status: "success",
                    onSubmit: () => {
                      hideMessage();
                      navigate("/top-up/iban-list");
                    },
                  }),
              })
            );
          }}
        >
          Submit
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

export default TopUpByBankTransfer;
