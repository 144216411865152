import { forwardRef, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
  CircularProgress as MuiCircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import CircularProgress from "../CircularProgress";
import Button from "../Button";
import CancelIcon from "@mui/icons-material/Cancel";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
// --------------------------------------------------------------------------------
let PDFJS = window.pdfjsLib;
// --------------------------------------------------------------------------------

const CustomFileUploader = ({ accept, preview, description, onChange }) => {
  const [uploadPercent, setUploadPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);

  const inputRef = useRef(null);

  const onChangeInput = async (e) => {
    let error = false;
    const errorObj = { ...error };

    let file = e.target.files[0];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    onChange({
      event: e,
      setUploadPercent,
      onSuccess: async () => {
        if (fileExtension === "pdf") {
          let _PDF_DOC = await PDFJS.getDocument(URL.createObjectURL(file));
          renderPage(_PDF_DOC);
        } else {
          setImage(URL.createObjectURL(file));
        }
      },
    });
    inputRef.current.value = "";
  };

  async function renderPage(pdf) {
    const canvas = document.createElement("canvas");

    const page = await pdf.getPage(1);
    const viewport = page.getViewport({ scale: 1 });
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const render_context = {
      canvasContext: canvas.getContext("2d"),
      viewport: viewport,
    };
    console.log("page lenght", pdf.numPages);

    await page.render(render_context).promise;
    let img = canvas.toDataURL("image/png");
    setImage(img);
  }

  return (
    <>
      <Card
        sx={{
          bgcolor: "transparent",
          // width: { md: 480 },
          borderStyle: "dashed",
          borderWidth: 2,
        }}
        variant="outlined"
      >
        {description && <CardContent>{description}</CardContent>}
        <CardContent>
          <Box
            sx={{
              // border: `1px dashed ${
              //   errors ? errorColor : gentlePeriwinkleColor
              // }`,
              borderRadius: 1.25,
              // width: 328,
              height: 155,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 1,
              // mb: 2,
              position: "relative",
              overflow: "hidden",
              // mx: "auto",
              cursor: preview ? "default" : "pointer",
            }}
            onClick={() => !preview && inputRef.current.click()}
          >
            {loading ? (
              <Box sx={{ my: "auto" }}>
                <MuiCircularProgress />
              </Box>
            ) : uploadPercent ? (
              <Box sx={{ my: "auto" }}>
                <CircularProgress value={uploadPercent} />
              </Box>
            ) : image ? (
              <>
                {!preview && (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen(true);
                      // showMessage({
                      //   title: "Are you sure?",
                      //   onSubmit: () => {
                      //     onDelete();
                      //     hideMessage();
                      //     setImage(null);
                      //   },
                      // });
                    }}
                    sx={{ position: "absolute", top: 0, right: 0, zIndex: 2 }}
                  >
                    <CancelIcon color="error" />
                  </IconButton>
                )}
                <Box
                  component="img"
                  alt=""
                  src={image}
                  sx={{
                    borderRadius: 1,
                    height: "100%",
                    maxWidth: "100%",
                    backgroundSize: "cover",
                  }}
                />
              </>
            ) : (
              <Box sx={{ textAlign: "center" }}>
                {/* <GalleryEditIcon
                  width={34}
                  height={34}
                  color={gentlePeriwinkleColor}
                  style={{ marginBottom: 2, marginBottom: 1 }}
                /> */}
                <AttachmentOutlinedIcon />
                <Box
                  sx={{
                    borderRadius: 10,
                    // border: `1px solid ${primaryColor}`,
                    px: 1,
                    py: 0.5,
                  }}
                >
                  <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                    +Add File
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <input
            ref={inputRef}
            type="file"
            onChange={onChangeInput}
            accept={accept}
            hidden
          />
          <Dialog open={open}>
            <DialogTitle sx={{ textAlign: "center" }}>
              Are you sure?
            </DialogTitle>
            <DialogActions>
              <Button
                variant="outlined"
                text="Cancel"
                // color="error"
                onClick={() => {
                  setOpen(false);
                }}
              />
              <Button
                text="Yes"
                onClick={() => {
                  setImage(null);
                  setOpen(false);
                }}
              />
            </DialogActions>
          </Dialog>
        </CardContent>
      </Card>
    </>
  );
};

export default CustomFileUploader;
