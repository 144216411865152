import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import debounce from "lodash/debounce";
import axios from "axios";
import {
  getUserWallet,
  topUpRequestAction,
  uploadFileRequestAction,
} from "redux/actions/usersActions";
import { calculateFeeRequestAction } from "redux/actions/GeneralActions";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "components/Autocomplete";
import DropDown from "components/DropDown";
import CustomFileUploader from "components/CustomFileUploader";
import AppNumberInput from "components/AppNumberInput";
import {
  removeBalanceSeparator,
  toFixedWithoutZeros,
} from "constants/usefulFunctions";
import { hideMessage, showMessage } from "components/Modal";
import { API_GET_SEARCH } from "constants/constants";
import { superAdmin } from "constant";
import { getAdminBalanceAction } from "redux/actions/adminActions";

const TopUpUser = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const roles = useSelector((state) => state.auth.user.roles);
  const authToken = useSelector((state) => state.auth.token);

  const [desc, setDesc] = useState("");
  const [amount, setAmount] = useState("0.0");
  const [commission, setCommission] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [file, setFile] = useState(null);
  const [userWallets, setUserWallets] = useState([]);
  const [wallet, setWallet] = useState("");
  const [userLimits, setUserLimits] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [adminBalance, setAdminBalance] = useState();
  const [minBalance, setMinBalance] = useState(0.0);
  const [balanceChanged, setBalanceChanged] = useState(false);
  const [errors, setErrors] = useState({});

  const topUpFee = (
    parseFloat(userLimits.userFee) +
    (parseFloat(userLimits.userPercentFee) / 100) * amount
  ).toFixed(2);

  useEffect(() => {
    if (inputValue.length > 3) request();
  }, [inputValue]);

  useEffect(() => {
    if ("id" in value && wallet)
      dispatch(
        calculateFeeRequestAction({
          eventId: 26,
          userId: value.id,
          walletId: wallet,
          setUserLimits,
        })
      );
    dispatch(getAdminBalanceAction(wallet, setAdminBalance));
  }, [value, wallet]);

  useEffect(() => {
    if (userWallets.length > 0) {
      const userWallet = userWallets.find((item) => item.id === wallet);
      if (userWallet) {
        setMinBalance(userWallet.minimumBalance);
      }
    }
  }, [userWallets, wallet]);

  const handleChange = debounce((newValue) => {
    setInputValue(newValue);
  }, 400);

  const request = () => {
    if (!loading) setLoading(true);
    axios
      .get(`${API_GET_SEARCH}?keyword=${String(inputValue)}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setOptions(res.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      `${option.firstName} ${option.lastName}` +
      option.email +
      option.mobile?.toString() +
      option.iban?.toString() +
      option.website_domain +
      option?.channel_name,
  });

  const handleClose = () => {
    setShowConfirm(false);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    setBalanceChanged(true);
  };

  const isSuperAdmin = roles.some((role) => role.name === superAdmin);

  const handleSubmit = () => {
    const errorObj = {};
    const amountNum = Number(amount);
    const topUpDifference = amountNum - Number(topUpFee);

    if (!superAdmin && amountNum >= adminBalance) {
      errorObj.amount = "Insufficient balance.";
    }

    const minLimit = +userLimits.min;
    const maxLimit = +userLimits.max;
    const currencySymbol = userLimits.symbol;

    if (amountNum < minLimit) {
      errorObj.amount = `Please enter an amount bigger than or equal to ${minLimit}${currencySymbol}.`;
    }
    if (amountNum > maxLimit) {
      errorObj.amount = `Please enter an amount less than or equal to ${maxLimit}${currencySymbol}.`;
    }
    if (topUpDifference > maxLimit || topUpDifference < minLimit) {
      errorObj.amount = `The charged amount is out of the acceptable limits!`;
    }

    setErrors(errorObj);
    if (Object.keys(errorObj).length > 0) return;

    setShowConfirm(true);
  };

  const renderDialogConfirm = (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={showConfirm}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Top-up User Wallet
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography gutterBottom>
          User: {value.firstName} {value.lastName}
        </Typography>
        <Typography gutterBottom>Email: {value.email}</Typography>
        <Typography gutterBottom>
          Amount: {toFixedWithoutZeros(amount)}
        </Typography>
        <Typography gutterBottom>
          Fee: {toFixedWithoutZeros(topUpFee)}
        </Typography>
        <Typography gutterBottom>
          Charged Amount: {toFixedWithoutZeros(amount - topUpFee)}
          {userLimits.symbol}
        </Typography>
        <Typography gutterBottom>Description: {desc}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() =>
            dispatch(
              topUpRequestAction({
                userId: value.id,
                walletId: wallet,
                amount: removeBalanceSeparator(amount),
                description: desc,
                file,
                setLoading,
                onSuccess: () => {
                  showMessage({
                    status: "success",
                    description: "Success",
                    onSubmit: () => {
                      hideMessage();
                      navigate("/top-up/list");
                    },
                  });
                },
              })
            )
          }
          loading={loading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <Card>
      <CardHeader title="Top-up User Wallet" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={3}>
            <div
              className={`searchbox right-side-icon bg-transparent d-block`}
              // style={{ width: "320px", maxWidth: "420px" }}
            >
              <Autocomplete
                id="combo-box-demo"
                fullWidth
                options={options}
                getOptionLabel={(option) => (option.email ? option.email : "")}
                loading={loading}
                filterOptions={filterOptions}
                renderOption={(e, option) => {
                  return (
                    <div
                      key={option.id}
                      onClick={(v) => {
                        // e.onClick(v);
                        // navigate(`/app/profile/${String(option.id)}/Summary`);
                        setValue(option);
                        dispatch(
                          getUserWallet({
                            userId: option.id,
                            setData: setUserWallets,
                          })
                        );
                      }}
                      {...e}
                      className={`${e.className} option-container`}
                    >
                      <div>
                        <span>Name: </span>
                        {String(option.firstName) +
                          " " +
                          String(option.lastName)}
                      </div>
                      {/* <div>
                    <span>Mobile:</span> {String(option.mobile)}
                  </div> */}
                      <div>
                        <span>Email:</span> {String(option.email)}
                      </div>
                    </div>
                  );
                }}
                onInputChange={(_, newValue) => {
                  handleChange(newValue);
                }}
                onChange={(_, newValue) => {
                  setValue(newValue);
                  dispatch(
                    getUserWallet({
                      userId: newValue.id,
                      setData: setUserWallets,
                    })
                  );
                  setLoading(false);
                }}
                value={value}
                disablePortal
                clearOnEscape
                autoHighlight
                blurOnSelect
                selectOnFocus
                handleHomeEndKeys
                onBlur={() => {
                  setInputValue("");
                  setLoading(false);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <div
                          style={{
                            backgroundColor: "white",
                            height: 36,
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          {loading && (
                            <CircularProgress color="primary" size={30} />
                          )}

                          {params.InputProps.endAdornment}
                        </div>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <DropDown
              label="Wallet"
              schema={{ label: "currency", value: "id" }}
              items={userWallets}
              onChange={(event) => setWallet(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <AppNumberInput
              thousandSeparator={true}
              label="Amount"
              onChange={handleAmountChange}
              value={amount}
              fullWidth
              decimalScale={2}
              error={errors.amount}
              helperText={errors.amount}
              maxLength={19}
              sx={{ boxSizing: "border-box" }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextField
              label="Description"
              onChange={(e) => setDesc(e.target.value)}
              value={desc}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <CustomFileUploader
              onChange={({ event, setUploadPercent, onSuccess }) => {
                let file = event.target.files[0];
                let formData = new FormData();
                formData.append("topup_receipt", file);
                dispatch(
                  uploadFileRequestAction({
                    formData,
                    setUploadPercent,
                    onSuccess: (data) => {
                      onSuccess();
                      setFile(data);
                    },
                  })
                );
              }}
            />
          </Grid>
          <Grid container item>
            <Grid
              item
              xs={12}
              sx={{ visibility: userLimits ? "visible" : "hidden" }}
            >
              <Typography sx={{ display: "inline" }}>
                Wallet topup fee:{" "}
              </Typography>
              <Typography sx={{ display: "inline", fontWeight: 600 }}>
                {Number(userLimits.userPercentFee) > 0 &&
                Number(userLimits.userFee) > 0
                  ? `${toFixedWithoutZeros(userLimits.userFee)}${
                      userLimits.symbol
                    } + ${toFixedWithoutZeros(
                      userLimits.userPercentFee
                    )}% = ${toFixedWithoutZeros(topUpFee)}${userLimits.symbol}`
                  : Number(userLimits.userFee) === 0 &&
                    Number(userLimits.userPercentFee) > 0
                  ? `${toFixedWithoutZeros(topUpFee)}${
                      userLimits.symbol
                    } (${toFixedWithoutZeros(userLimits.userPercentFee)}%)`
                  : Number(userLimits.userPercentFee) > 0
                  ? `${toFixedWithoutZeros(userLimits.userPercentFee)}% `
                  : `${toFixedWithoutZeros(topUpFee)}${userLimits.symbol}`}
              </Typography>
            </Grid>
            {!isSuperAdmin && (
              <Grid
                item
                xs={12}
                sx={{ visibility: userLimits ? "visible" : "hidden" }}
              >
                <Typography sx={{ display: "inline" }}>
                  Admin Balance :{" "}
                </Typography>
                <Typography sx={{ display: "inline", fontWeight: 600 }}>
                  {/* {parseFloat(adminBalance).toFixed(2) } {userLimits.symbol} */}
                  {toFixedWithoutZeros(adminBalance)} {userLimits.symbol}
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{ visibility: userLimits ? "visible" : "hidden" }}
            >
              <Typography sx={{ display: "inline" }}>
                Minimum Amount:{" "}
              </Typography>
              <Typography sx={{ display: "inline", fontWeight: 600 }}>
                {toFixedWithoutZeros(userLimits.min)} {userLimits.symbol}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ visibility: userLimits ? "visible" : "hidden" }}
            >
              <Typography sx={{ display: "inline" }}>
                Maximum Amount:{" "}
              </Typography>
              <Typography sx={{ display: "inline", fontWeight: 600 }}>
                {userLimits.max} {userLimits.symbol}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          disabled={
            !isSuperAdmin &&
            (!value.id ||
              !wallet ||
              desc.length === 0 ||
              amount - topUpFee > +userLimits.max ||
              amount - topUpFee < +userLimits.min ||
              Number(amount) >= adminBalance)
          }
        >
          Submit
        </LoadingButton>
      </CardActions>
      {renderDialogConfirm}
    </Card>
  );
};

export default TopUpUser;
