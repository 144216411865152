import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, IconButton, Grid, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { downloadFileRequestAction } from "redux/actions/usersActions";
import FileSaver from "file-saver";
import DropDown from "components/DropDown";
import { getAgentsListRequestAction } from "redux/actions/agentActions";
import { getCurrenciesListRequestAction } from "redux/actions/currencyAction";
import { GET_TOPUP_CARD_LIST, GET_TOPUP_LIST } from "config/apiRoutes";
import PaginationTable from "components/PaginationTable";
// --------------------------------------------------------------------------------
const TopUpCardList = () => {
  const dispatch = useDispatch();

  const { agentsList, currenciesList } = useSelector((state) => ({
    agentsList: state.agent.agentsList,
    currenciesList: state.currency.currenciesList,
  }));

  const [agent, setAgent] = useState(0);
  const [currency, setCurrency] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const paginationTableRef = useRef();

  useEffect(() => {
    dispatch(getAgentsListRequestAction());
    dispatch(getCurrenciesListRequestAction());
  }, []);

  useEffect(() => {
    !isInitialLoad
      ? paginationTableRef.current.refetch()
      : setIsInitialLoad(false);
  }, [agent, currency]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "user",
        header: "User's Full Name",
        // size: 150,
      },
      {
        accessorKey: "admin",
        header: "Admins's Full Name",
        // size: 150,
      },
      {
        accessorKey: "agent",
        header: "Agent",
        size: 150,
      },
      {
        accessorKey: "amount", //normal accessorKey
        header: "Amount",
        // size: 150,
      },
      {
        accessorKey: "currency",
        header: "Currency",
      },
      {
        accessorKey: "fee",
        header: "Fee",
      },
      {
        accessorKey: "date",
        header: "Date",
      },
      {
        accessorKey: "description",
        header: "Description",
      },
      {
        accessorKey: "file",
        header: "FIle",
        Cell: ({ cell, row }) =>
          row.original.file && (
            <IconButton
              onClick={() =>
                dispatch(
                  downloadFileRequestAction({
                    fileAddress: row.original.file,
                    onSuccess: (response) => {
                      let base64Data = `data:${response.data.type};base64,${response.data.value}`;
                      FileSaver.saveAs(
                        base64Data,
                        `WalletTopUp_${row.original.date}`
                      );
                    },
                  })
                )
              }
            >
              <AttachFileOutlinedIcon />
            </IconButton>
          ),
      },
    ],
    []
  );

  return (
    <PaginationTable
      ref={paginationTableRef}
      title="Top-up Card List"
      apiEndpoint={GET_TOPUP_CARD_LIST}
      columns={columns}
      renderTopToolbar={
        <>
          <Grid item>
            <Box width={238}>
              <DropDown
                label="Agent"
                schema={{ label: "name", value: "id" }}
                items={[{ name: "ALL", id: 0 }, ...agentsList]}
                value={agent}
                onChange={(event) => setAgent(event.target.value)}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box width={238}>
              <DropDown
                label="Currency"
                schema={{ label: "name", value: "id" }}
                items={[{ name: "ALL", id: 0 }, ...currenciesList]}
                value={currency}
                onChange={(event) => setCurrency(event.target.value)}
              />
            </Box>
          </Grid>
        </>
      }
      initialFilters={{ agentId: agent, currencyId: currency }}
    />
  );
};

export default TopUpCardList;
