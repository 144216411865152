import React from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="app-footer">
      <span className="d-inline-block">
        Copyright Allpay &copy; {currentYear}
      </span>
      <Link className="jr-list-link" to="/app/faq">
        <span className="jr-list-text">FAQ</span>
        <i className="zmdi zmdi-help zmdi-hc-fw zmdi-hc-lg" />
      </Link>
    </footer>
  );
};

export default Footer;
